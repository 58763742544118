<template>
  <div id="chart">
    <apexchart @click="selectedBar" type="bar" height="auto" :options="chartOptions" :series="series"></apexchart>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xxl modal-dialog-centered" style="max-width: 80vw;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{!!currentEvent ? currentEvent.event : ''}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row align-item-center">
              <div class="col-auto pb-2 mr-2">
                <nav aria-label="...">
                  <ul class="pagination text-smallest mb-0 pb-0">
                    <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
                      <button class="page-link" @click="setPage(1,year,office)">First</button>
                    </li>

                    <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
                      <button class="page-link" @click="setPage(page,year,office)">{{ page }}</button>
                    </li>

                    <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
                      <button class="page-link" @click="setPage(pagination.lastPage,year,office)">Last</button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-auto">
                <select name="year" id="year" v-model="year" class="form-control form-control-sm">
                  <option :value="null">{{Math.min(...years)}}-{{Math.max(...years)}}</option>
                  <option :value="year" v-for="year in years">
                    {{year}}
                  </option>
                </select>
              </div>
              <div class="col-auto">
                <button class="btn btn-dark btn-sm force-white-all" :disabled="!accidentIncidentPerEvent.length" @click.prevent="generateCsv"><font-awesome-icon icon="download" class="mr-2"></font-awesome-icon>DOWNLOAD CSV</button>
              </div>
            </div>

            <table class="small e-tbl">
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">

              <template v-if="$route.params.chart_module === 'number_of_report'">
                <tr>
                  <th class="align-middle text-center" style="width: 1em" scope="col">#</th>
                  <th class="align-middle text-center" scope="col">VESSEL</th>
                  <th class="align-middle text-center" scope="col">MANAGER</th>
                  <th class="align-middle text-center" scope="col">REPORT TITLE</th>
                  <th class="align-middle text-center" scope="col">EVENT DATE</th>
                  <th class="align-middle text-center" scope="col">EVENT 1</th>
                  <th class="align-middle text-center" scope="col">EVENT 2</th>
                  <th class="align-middle text-center" scope="col">EVENT 3</th>
                  <th class="align-middle text-center" scope="col">EVENT 4</th>
                  <th class="align-middle text-center" scope="col">ACTION</th>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th rowspan="2" class="align-middle text-center" style="width: 1em" scope="col">#</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">VESSEL</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">MANAGER</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">REPORT TITLE</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">EVENT DATE</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">EVENT 1</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">EVENT 2</th>
                  <th rowspan="2" class="align-middle text-center" scope="col">EVENT 3</th>
                  <th rowspan="2" style="width: 200px"  class="align-middle text-center" scope="col">EVENT 4</th>
                  <th class="align-middle text-center" scope="col" colspan="4">SCHEDULE DELAY <br>
                    <div class="font-weight-bold" style="font-size: 14px">({{ Number(accidentIncidentPerEvent.map(item => parseFloat(item.lossTotalDelayedHours)).reduce((acc, curr) => acc + curr, 0)).toFixed(2) }} Hour/s) </div>
                  </th>
                  <th rowspan="2" class="align-middle text-center" scope="col">ACTION</th>
                </tr>
                <tr>
                  <th>Day/s</th>
                  <th>Hour/s</th>
                  <th>Minute/s</th>
                  <th>Total Hour/s</th>
                </tr>
              </template>

              </thead>
              <tbody>
              <template v-if="loading && !accidentIncidentPerEvent.length">
                <tr>
                  <td colspan="100%">
                    <app-loading></app-loading>
                  </td>
                </tr>
              </template>
              <template v-else-if="!loading && accidentIncidentPerEvent.length">
                <tr v-for="(evt,index) in accidentIncidentPerEvent ">
                  <td class="align-middle text-center">{{index + 1}}</td>
                  <td class="align-middle text-center">{{evt.vessel}}</td>
                  <td class="align-middle text-center">{{evt.managementOffice}}</td>
                  <td class="align-middle text-center">{{evt.title}}</td>
                  <td class="align-middle text-center">{{evt.eventDate}}</td>
                  <td class="align-middle text-center">{{evt.eventOne}}</td>
                  <td class="align-middle text-center">  {{evt.eventTwo}}</td>
                  <td class="align-middle text-center">{{evt.eventThree}}</td>
                  <td class="align-middle text-center">{{evt.eventFour}}</td>

                  <template v-if="$route.params.chart_module !== 'number_of_report'">
                    <td class="align-middle text-center">{{evt.delaySchedDay}}</td>
                    <td class="align-middle text-center">{{evt.delaySchedHours}}</td>
                    <td class="align-middle text-center">{{evt.delaySchedMin}}</td>
                    <td class="align-middle text-center">{{evt.lossTotalDelayedHours}}</td>
                  </template>



                  <td class="align-middle text-center">
                    <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click="showReport(evt.id)">
                      <font-awesome-icon icon="eye"  />
                      View
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <td colspan="100%" class="align-middle text-center">
                  <p class="h5 py-1">NO ACCIDENT INCIDENTS FOUND</p>
                </td>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import appLoading from "@/components/elements/AppLoading.vue";
import {ExcelService} from "@/services/ExcelService";

export default {
  name: "AccidentIncidentYearlyChart",
  props:{
    series:{
      type:Object | Array,
      default:null,
    },
    categories:{
      type: Array,
      default: null
    },
    years:{
      type: Array,
      default: null
    },
    categoryObject:{
      type:Array|Object,
      default:null
    },
    office:{
      type:String|Number,
      default:null,
    }
  },
  mixins:[PaginationMixin],
  components:{appLoading},
  data() {
    return {
      year:null,
      loading:false,
      currentEvent:null,
      chartOptions: {
        chart: {
          type: 'bar',
          events:{
            xAxisLabelClick: function(event, chartContext, config){
              return event.target.innerHTML;
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            vertical: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          colors: ['#000']
        },
        tooltip: {
          followCursor : true,
          shared: true,
          intersect: false
        },
        fill: {
          colors: ['#bfbfbf', '#a6a6a6', '#ffd966', '#f7caac', '#f4b083', '#ff0000']
        },
        grid: {
          borderColor: '#e9e9e9',
          position: 'front',
          xaxis: {
            lines: {
              show: true,
            }
          }
        },
        xaxis: {
          categories: this.categories,
        },
      },
    }
  },
  methods:{
    ...mapActions(['getAccidentIncidentByEvent']),
    async selectedBar(evt){
      const isDownloadMenuItem = $(evt.target).hasClass('apexcharts-menu-item');
      if (isDownloadMenuItem === true) {
        return;
      }

      const innerText = evt.target.innerHTML;
      const loweredInnerText = innerText.toString().substring(0, 1).toLowerCase()
      if(this.categoryLetters.includes(loweredInnerText)){
        this.currentEvent=this.categoryObject.find((evt2) => {
          const loweredEvt = evt2.event.substring(0, 1).toLowerCase()

          return loweredEvt == loweredInnerText;
        });

        $('#exampleModal').modal('show');
        this.loading=true;
        this.year=null;
        await this.setPage(null,null,this.office);
        this.loading=false;
      }
    },
    async setPage(page = null,year=null,office=null){
      let params = {
        eventOne:this.currentEvent?.eventOneId,
        page:page ?? 1,
        year:year ?? null,
        office: office ?? null
      }

      if (this.$route.params.chart_module !== 'number_of_report') {
        params.delay_module = this.$route.params.chart_module;
      }
      this.loading=true;
      await this.getAccidentIncidentByEvent(params);
      this.loading=false;
    },
    async generateCsv(){
      swal.fire({
        'title': 'GENERATING CSV FILE',
        allowEscapeKey:false,
        allowEnterKey:false,
        allowOutsideClick:false,
      })
      swal.showLoading()
      await ExcelService.csv('ACCIDENT_INCIDENT_EVENT',{eventOne:this.currentEvent?.eventOneId ?? null,year:this.year ?? null,office:this.office})
      swal.close();
    },
    async showReport (reportId) {
      const routeName = this.$route.params.chart_module === 'number_of_report' ? 'AccidentIncidentReport' : 'Loss'
      const route= this.$router.resolve(
        {
          name: routeName,
          params: {
            id: reportId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
  },
  computed:{
    ...mapGetters(['accidentIncidentPerEvent','pagination']),
    categoryLetters(){
      return this.categories.map( evt=>{
        return evt.substring(0, 1).toLowerCase()
      })
    }
  },

  watch:{
    'year'(val){
      this.setPage(null,val,this.office)
    }
  }
}
</script>

<style scoped>
table tr td{
  font-size: 14px !important;
}
</style>
