<template>
  <table class="table text-center table-bordered table-sm text-dark mb-1">
    <thead>
    <tr class="text-center">
      <th class="lead bg-ffd966">Description</th>
      <th v-for=" (year,index) in years " :class="{
        'lead bg-bfbfbf' : index ===0,
        'lead bg-a6a6a6' : index ===1,
        'lead bg-ffd966' : index ===2,
        'lead bg-f7caac' : index ===3,
        'lead bg-f4b083' : index ===4,
        'lead bg-ff0000' : index ===5,
      }"> {{year}}</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="item in data">
        <td>{{item.event}}</td>
        <td v-for="(year) in years">
          {{item.years[year]}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AccidentIncidentYearlyTable",
  props:{
    data:{
      type:Object | Array,
      default:null
    },
    years:{
      type:Object | Array,
      default: null
    },
  }
}
</script>

<style scoped>

</style>
