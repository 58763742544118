var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-4"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.office_id),expression:"office_id"}],staticClass:"form-control form-control-sm",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.office_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v(" SELECT BRANCH OFFICE")]),_vm._l((_vm.mngmntOfficeKeys),function(office){return _c('option',{domProps:{"value":office.id}},[_vm._v(" "+_vm._s(office.name ? office.name.toUpperCase() : '')+" ")])})],2)])]),_c('div',{staticClass:"row mb-2 justify-content-sm-between"},[_c('div',{staticClass:"col-auto"},[_c('nav',{staticClass:"navbar navbar-expand-lg mt-2",attrs:{"id":"ModelNav"}},[_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse justify-content-center",attrs:{"id":"navbarNav"}},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.requestNewChart('number_of_report', _vm.office_id)}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                  chart_module : 'number_of_report'
                }}}},[_c('span',{staticStyle:{"height":"100%"}},[_vm._v("Number of Report")])])],1),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.requestNewChart('number_of_delay', _vm.office_id)}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                  chart_module : 'number_of_delay'
                }}}},[_c('span',[_vm._v("Number Of Delay")])])],1),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.requestNewChart('delay_hours', _vm.office_id)}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                  chart_module : 'delay_hours'
                }}}},[_c('span',[_vm._v("Delay Hours")])])],1)])])])]),_c('div',{staticClass:"col-auto pt-2"},[_c('button',{staticClass:"btn btn-dark btn-sm force-white-all",on:{"click":function($event){$event.preventDefault();return _vm.generateCsv.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"download"}}),_vm._v("DOWNLOAD CSV")],1)])]),(_vm.loading || _vm.loadingChart)?_c('div',[_c('app-loading')],1):[_c('div',{staticClass:"card shadow-sm mb-4"},[_c('div',{staticClass:"card-body pb-2"},[_c('div',[_c('ai-yearly-chart',{attrs:{"category-object":_vm.airChartCounts,"years":_vm.years,"series":_vm.chartInfo,"office":_vm.office_id,"categories":_vm.chartCategories}})],1)])]),_c('div',{staticClass:"card shadow-sm mb-4"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"e-text-red-light-1"},[_vm._v("Accident / Incident Data "+_vm._s(_vm.$route.params.chart_module === 'delay_hours' ? 'via Hours' : ''))]),_c('ai-yearly-table',{attrs:{"data":_vm.airChartCounts,"years":_vm.years}})],1)])]],2),_c('div',{staticClass:"col-3"},[_c('h2',{staticClass:"h2 e-text-red"},[_vm._v("Reports")]),_c('div',{staticClass:"card shadow-sm rounded mb-3"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"e-text-red-light-1"},[_vm._v("Accident / Incident")]),(_vm.loading)?[_c('app-loading')]:_c('table',{staticClass:"table mb-1"},_vm._l((_vm.airReportStatusCounts),function(airCount){return _c('tr',[_c('th',[_vm._v(_vm._s(airCount.name))]),_c('th',[_vm._v(_vm._s(airCount.count))])])}),0)],2)]),_vm._m(2),_vm._m(3),_vm._m(4)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8"},[_c('h2',{staticClass:"h2 e-text-red"},[_vm._v("Accident / Incident Yearly Report")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNav","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow-sm rounded mb-3"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"e-text-red-light-1"},[_vm._v("Inspection")]),_c('h6',{staticClass:"e-text-red-light-1"},[_vm._v("PSC Inspection")]),_c('table',{staticClass:"table mb-1"},[_c('tr',[_c('th',[_vm._v("NEW")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("OPEN")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("READY TO CLOSE")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("CLOSED")]),_c('th',[_vm._v("0")])]),_c('tr',{staticClass:"border-bottom"},[_c('th',[_vm._v("RECORD ONLY")]),_c('th',[_vm._v("0")])])]),_c('h6',{staticClass:"e-text-red-light-1"},[_vm._v("Rightship Inspection")]),_c('table',{staticClass:"table mb-1"},[_c('tr',[_c('th',[_vm._v("NEW")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("OPEN")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("READY TO CLOSE")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("CLOSED")]),_c('th',[_vm._v("0")])]),_c('tr',{staticClass:"border-bottom"},[_c('th',[_vm._v("RECORD ONLY")]),_c('th',[_vm._v("0")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow-sm rounded mb-3"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"e-text-red-light-1"},[_vm._v("Audit")]),_c('table',{staticClass:"table mb-1"},[_c('tr',[_c('th',[_vm._v("NEW")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("OPEN")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("READY TO CLOSE")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("CLOSED")]),_c('th',[_vm._v("0")])]),_c('tr',{staticClass:"border-bottom"},[_c('th',[_vm._v("RECORD ONLY")]),_c('th',[_vm._v("0")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow-sm rounded mb-3"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"e-text-red-light-1"},[_vm._v("Master's Review and Improvement")]),_c('table',{staticClass:"table mb-1"},[_c('tr',[_c('th',[_vm._v("NEW")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("OPEN")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("READY TO CLOSE")]),_c('th',[_vm._v("0")])]),_c('tr',[_c('th',[_vm._v("CLOSED")]),_c('th',[_vm._v("0")])]),_c('tr',{staticClass:"border-bottom"},[_c('th',[_vm._v("RECORD ONLY")]),_c('th',[_vm._v("0")])])])])])
}]

export { render, staticRenderFns }